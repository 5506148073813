export async function setStyleSheets(response: any) {
	const styleSheets = Array.from(document.styleSheets).filter(
		(styleSheet) => {
			return (
				!styleSheet.href ||
				styleSheet.href.startsWith(window.location.origin)
			);
		}
	);

	for (let style of styleSheets) {
		if (style instanceof CSSStyleSheet && style.cssRules) {
			for (const rule of Object.entries(style.cssRules)) {
				if (rule[1]["selectorText"] == ":root") {
					//Imagenes
					rule[1]["style"].setProperty(
						"--image-login",
						"url(" + response.logo + ")"
					);
					rule[1]["style"].setProperty(
						"--image-lef-login",
						"url(" + response.logo + ")"
					);
					//Background Color
					rule[1]["style"].setProperty(
						"--background-color",
						response.background_color
					);
					//Primary and secondary color
					rule[1]["style"].setProperty(
						"--v-primary-base",
						response.input_color
					);
					rule[1]["style"].setProperty(
						"--v-secondary-base",
						response.button_color
					);
					rule[1]["style"].setProperty(
						"--title-color",
						response.text_color
					);
					rule[1]["style"].setProperty(
						"--link-color",
						response.input_color
					);
					rule[1]["style"].setProperty(
						"--input-color-login",
						response.input_color
					);
					rule[1]["style"].setProperty(
						"--button-color-login",
						response.button_color
					);
					rule[1]["style"].setProperty(
						"--button-text-color-login",
						response.button_text_color
					);
					rule[1]["style"].setProperty(
						"--link-color-login",
						response.link_color
					);
				}
			}
		}
	}
}
