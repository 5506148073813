import Vue from "vue";
export default Vue.extend({
	name: "AuthSkeleton",
	model: {},
	props: {},
	components: {},
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {},
	methods: {},
	watch: {},
});
